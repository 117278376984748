@tailwind base;
@layer base {
    @import "sass/base/base";
}
@tailwind components;
@tailwind utilities;

// Classes that have to be out of the tree-shaking process

// Slick Slider - Default
.slick {
    &-slider {
        @apply relative;
    }

    &-track {
        @apply flex;
    }

    &-slide {
        @apply h-auto;

        & > div {
            @apply h-full;
        }
    }

    &-arrow {
        @apply absolute bottom-0 left-0 m-auto z-10 text-grey-500 hover:text-black transition-all duration-300;

        &.slick-next {
            @apply left-8;
        }
    }

    &-dots {
        @apply list-none mt-5;

        li {
            @apply inline-block mr-2 last:mr-0;

            button {
                @apply bg-grey-300 rounded-full transition-all duration-300 block w-4 h-4 cursor-pointer text-transparent border-0 outline-0 focus:outline-0 hover:outline-0;
            }

            &.slick-active {
                button {
                    @apply bg-black;
                }
            }
        }
    }
}

// Testimonial Slider
.slider--testimonials {
    .slick {
        &-arrow {
            @apply hover:text-orange;
        }
    }
}

// Post Slider
.slider--posts {
    .slick {
        &-dots {
            li {
                &.slick-active {
                    button {
                        @apply bg-orange;
                    }
                }
            }
        }
        &-arrow {
            @apply left-auto right-8 hover:text-orange;

            &.slick-next {
                @apply left-auto right-0;
            }

        }
    }
}

// Banner Slider
.slider--banner {
    .slick {
        &-dots {
            @apply absolute bottom-8 right-28;
            li {
                button {
                    @apply bg-white/50;
                }
                &.slick-active {
                    button {
                        @apply bg-white;
                    }
                }
            }
        }
    }
}

